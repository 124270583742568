
.section {
    display: flex;
    flex-direction: row;
    padding: 10px;
    font-family: arial;
    font-size: 25px;
    width: 100%;
    /*outline: dashed 1px black;*/
    text-align: left;
    flex-direction:column;
    margin-top: 20px;
  }

  .labels
  {
    display: flex;
    flex-wrap: wrap;
  }

  .label
  {
      /*min-width: 180px;*/
      font-size: 12px;
      height: 30px;
      border: solid 1px gray;
      border-radius: 10px;
      text-align: center;
      vertical-align: middle;
      padding: 5px;
      display: table-cell;
      margin: 5px;
  }